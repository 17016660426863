import {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'

const Routes: FC = () => {
    const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

    return (
        <Switch>
            {!isAuthorized ? (
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                <Redirect from='/auth' to='/' />
            )}

            <Route path='/error' component={ErrorsPage} />
            <Route path='/logout' component={Logout} />

            {!isAuthorized ? (
                <Redirect to='/autenticacao/entrar' />
            ) : (
                <MasterLayout>
                    <PrivateRoutes />
                </MasterLayout>
            )}
        </Switch>
    )
}

export {Routes}
