import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export enum SolicitationRoutesEnum {
    DatatableExam = '/solicitacoes/exames',
    ShowExam = '/solicitacoes/exames/:id',
    RegisterExam = '/solicitacoes/exames/registrar',
}

export function SolicitationRouter() {
    /*Rotas para Dashboard*/
    const ShowExam = lazy(() => import('../../pages/solicitation/exams/ShowExam'))
    const DatatableExam = lazy(() => import('../../pages/solicitation/exams/DatatableExam'))
    const RegisterExam = lazy(() => import('../../pages/solicitation/exams/RegisterExam'))

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route path={SolicitationRoutesEnum.RegisterExam} component={RegisterExam} />
            <Route path={SolicitationRoutesEnum.ShowExam} component={ShowExam} />
            <Route path={SolicitationRoutesEnum.DatatableExam} component={DatatableExam} />
        </Switch>
    )
}
