import {Spinner} from 'react-bootstrap-v5'

interface Props {
    children?: React.ReactNode
    loading: boolean
}
export function Container({children, loading}: Props) {
    return (
        <main>
            {loading ? (
                <span className='w-100 d-flex align-items-center justify-content-center h-300px'>
                    <Spinner animation='grow' variant='primary' />
                </span>
            ) : (
                children
            )}
        </main>
    )
}
