import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export default function BillingRouter() {
    const ApprovedNotes = lazy(() => import('../../pages/billing/fiscalNotes/ApprovedNotes'))
    const PendingNotes = lazy(() => import('../../pages/billing/fiscalNotes/PendingNotes'))
    const ProfileNotes = lazy(() => import('../../pages/billing/fiscalNotes/ProfileNotes'))

    const WalletRubbyPage = lazy(() => import('../../pages/billing/wallets/DatatableRubyyWallet'))
    const WalletClinicPage = lazy(() => import('../../pages/billing/wallets/ListClinicWallet'))
    const WalletDoctorPage = lazy(() => import('../../pages/billing/wallets/ListDoctorWallet'))

    const DatatableInvoice = lazy(() => import('../../pages/billing/invoices/DatatableInvoice'))
    const ShowInvoice = lazy(() => import('../../pages/billing/invoices/ShowInvoice'))

    const HistoricPage = lazy(() => import('../../pages/billing/historics/ListHistoric'))
    const ShowHistoric = lazy(() => import('../../pages/billing/historics/ShowHistoric'))

    const DatatableTaxes = lazy(() => import('../../pages/billing/taxes/DatatableTaxes'))
    return (
        <Switch>
            <Route path='/financeiro/notas-fiscais/aprovadas' component={ApprovedNotes} />
            <Route path='/financeiro/notas-fiscais/pendentes' component={PendingNotes} />
            <Route path='/financeiro/notas-fiscais/:id' component={ProfileNotes} />

            <Route path='/financeiro/conta-corrente/rubyy' component={WalletRubbyPage} />
            <Route path='/financeiro/conta-corrente/estabelecimento' component={WalletClinicPage} />
            <Route path='/financeiro/conta-corrente/profissional' component={WalletDoctorPage} />

            <Route path='/financeiro/faturamento/:id' component={ShowInvoice} />
            <Route path='/financeiro/faturamento' component={DatatableInvoice} />

            <Route path='/financeiro/transacoes/:id' component={ShowHistoric} />
            <Route path='/financeiro/transacoes' component={HistoricPage} />

            <Route path='/financeiro/taxas' component={DatatableTaxes} />
        </Switch>
    )
}
